
<script>
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') //+ '-page'
    },
  },
  mounted() {
    this.$store.dispatch('loadCart')
    window.addEventListener('beforeunload', this.saveData);
  },
  onBeforeUnmount() {
    console.log('main layout beforeUnmount ')
    window.removeEventListener('beforeunload', this.saveData);

  },
  methods: {
    saveData() {


      sessionStorage.setItem('cart', JSON.stringify(this.$store.state.cart.cartProducts));
    }
  },



}



</script>


<template>
	<component :is="layout">
		<router-view />
	</component>
</template>