<template>
	<div class="main__layout" :class="{mobile:isMobile , desctop:!isMobile}">
		<HeaderLayout />
		<div class="page__container">
			<div class=" page__content">
	
	
				<router-view v-slot="{ Component }">
					<Transition name="slide-fade" :duration="500">
						<component :is=" Component" />
	
					</Transition>
				</router-view>
			</div>
		</div>
	</div>
	<ShowCartButtonVue />
	<FooterLayout />
</template>

<script>
import HeaderLayout from "@/components/Header.vue"
import FooterLayout from "@/components/Footer.vue"
// import S from "@/components/Footer.vue"
import ShowCartButtonVue from '@/components/cart/ShowCartButton.vue'


export default {
  name: "main-layout",
  components: { HeaderLayout, FooterLayout, ShowCartButtonVue },
  computed: {

    isMobile() {
      return this.$screen()
    },


  },



  async mounted() {
    if (this.$store.getters.info) {
      //  if (!Object.keys( this.$store.getters.info).length > 0) {
      await this.$store.dispatch('fetchInfo')
    }
  },
}
</script>

 <style lang="scss">
@use 'sass:math';
@import '../scss/config/functions';
@import '../scss/config/mixins';
@import '../scss/config/patternSelectors';
@import '../scss/config/vars.scss';



.scroll {
	position: fixed;
	left: 50px;
	top: 50px;
}

.slide-fade-enter-active {
	// transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); //cubic-bezier(1, 0.5, 0.8, 1); //ease-out;
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); //cubic-bezier(1, 0.5, 0.8, 1); //ease-out;
	background: $bgColor;
	filter: blur(rem(20));

	width: 100%;
}

.slide-fade-leave-active {
	filter: blur(100px);

	background: $bgColor;
	width: 100%;
	transition: all .5s cubic-bezier(1, 0.5, 0.8, 1); //cubic-bezier(1, 0.5, 0.8, 1)
	//	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1); //cubic-bezier(1, 0.5, 0.8, 1)
	// background: $green;
}

.slide-fade-enter-from {
	display: flex;
	transform: translateX(-500px);
	opacity: 1;

}

.slide-fade-leave-to {

	transform: translateX(-500px);
	opacity: 1;

}
</style> 
