<template>
	<div class="empty__layout">
		<div class="page__container">
	
			<router-view />
	
		</div>
	</div>
</template>


<!-- <style lang="scss">
@import '../scss/style.scss';


.slide-fade-enter-active {
	transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1); //ease-out;
	background: red;
	filter: blur(rem(20));
	//min-height: 50vh;
}

.slide-fade-leave-active {
	filter: blur(100px);
	//min-height: 50vh;
	background: blue;
	transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
	// background: $green;
}
</style> -->
<script>
import messages from '@/lists/messageList'
export default {
  computed: {
    error() {
      return this.$store.getters.error
    },
  },
  watch: {
    error(FirebaseError) {
      let message = ''
      switch (FirebaseError.code) {
        case 'auth/invalid-credential':
          message = messages.userInvalid
          break
        case 'auth/email-already-in-use':
          message = messages.usedEmail
          break

        default:
          message = messages.baseError
          break
      }

      this.$errorMessage(message)

    }
  }
}
</script>