export default {
  state: {
    cartProducts: [],
    cartTotal: null,
  },
  mutations: {
    getTotal(state) {
      state.cartTotal = state.cartProducts
        .map((el) => Number(el.price) * el.count)
        .reduce((acc, curr) => acc + curr, 0)
    },

    load(state) {
      if (sessionStorage.getItem("cart") === null || undefined) {
        console.log("cart is empty.....")
        return
      }
      state.cartProducts = JSON.parse(sessionStorage.getItem("cart"))
      this.commit("getTotal")
    },

    deleteProduct(state, id) {
      const productIndex = state.cartProducts.findIndex(
        (item) => item.id === id,
      )

      if (productIndex !== -1) {
        state.cartProducts.splice(productIndex, 1)
        this.commit("getTotal")
      }
      // state.cartProducts.splice(productIndex, 1)
      // this.commit("getTotal")
    },

    decrementProduct(state, id) {
      const productIndex = state.cartProducts.findIndex(
        (item) => item.id === id,
      )

      if (productIndex !== -1) {
        const product = state.cartProducts[productIndex]
        if (product.count > 1) {
          product.count -= 1
        } else {
          state.cartProducts.splice(productIndex, 1)
        }
      }

      this.commit("getTotal")
    },

    incrementProduct(state, id) {
      console.log(id)
      const existingProduct = state.cartProducts.find((item) => item.id === id)

      if (existingProduct) {
        existingProduct.count += 1
      } else {
        product.count = 1
        state.cartProducts.push(product)
      }
      this.commit("getTotal")
      // state.cartProducts.push(product)
      // state.cartTotal = state.cartProducts
      //   .map((el) => Number(el.price) * el.count)
      //   .reduce((acc, curr) => acc + curr, 0)
    },

    addProduct(state, product) {
      const existingProduct = state.cartProducts.find(
        (item) => item.id === product.id,
      )

      if (existingProduct) {
        existingProduct.count += 1
      } else {
        product.count = 1
        state.cartProducts.push(product)
      }

      // state.cartProducts.push(product)
      // state.cartTotal = state.cartProducts
      //   .map((el) => Number(el.price) * el.count)
      //   .reduce((acc, curr) => acc + curr, 0)
      this.commit("getTotal")
      // sessionStorage.setItem("cart", JSON.stringify(state.cartProducts))
    },
    clearCart(state) {
      state.cartTotal = null
      state.cartProducts = []
    },
  },
  actions: {
    addToCart({ dispatch, commit }, product) {
      commit("addProduct", product)
    },

    loadCart({ dispatch, commit }) {
      commit("load")
    },
  },
  getters: {
    getProductCount: (state) => (productId) => {
      const item = state.cartProducts.find((p) => p.id === productId)
      return item ? item.count : 0
    },

    cart: (s) => s.cart,
  },
}
