<template>
	<nav class="header__menu mobile-menu" :class="{open: isOpen}">
	
		<routerLink v-for=" link in links" :key="link.url" :to="link.url" @click="isOpen =false" class="menu__item">
			{{ link.title }}
		</routerLink>
		<WorkTime />
	</nav>
	
	<button class="header__burger icon-menu" @click.prevent="isOpen =!isOpen" :class="{open: isOpen}">
		<span></span>
	</button>
</template>

<script>

import menuLists from '@/lists/menuLists';
import WorkTime from './WorkTime.vue';
export default {
  components: { WorkTime },


  data: () => ({
    isOpen: false,
    links: menuLists.mainMenu
  }),
  mounted() {
    this.ref = this.$refs.element;
  }

}
</script>

<style lang="scss">
@use "sass:math";
@import "src/scss/config/functions";
@import "src/scss/config/mixins";
@import "/src/scss/config/patternSelectors";
@import "src/scss/config/vars.scss";

.mobile-menu {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	width: 100vw;
	height: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	opacity: 100;
	background: $redColor;
	z-index: 99;
	overflow: hidden;
	gap: rem(20);


	&.open {
		height: 100vh;
		transition: 0.3s;
		color: $bgColor;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: fixed;
		background-color: $blackColor;
		opacity: 1;
		z-index: 99;


		a {
			color: $whiteColor;
		}

		.menu__item {
			box-sizing: content-box;



			&.active {
				background: $redColor;
				border-radius: 50px;
			}

			@for $i from 1 through 4 {
				color: $bgColor;
				display: flex; // :nth-child(1-5)
				padding: rem(8) rem(16);
				@include adaptiv-font(30, 20);

				&:nth-child(#{$i}) {
					animation-name: load;
					animation-duration: 1s;
					$itemDelay: 0.06s;
					animation-delay: $itemDelay * $i;
				}

			}
		}

		//	@include media(425) {
		//		.time__block {
		//			display: flex;
		//			flex-direction: column;
		//		position: absolute;
		//		top: rem(30);
		//		left: rem(30);

		//		span {
		//			color: $bgColor;
		//		}
		//	}
		//		}
	}

	// }
}

// Burger

.icon-menu {
	order: 3;
	display: block;
	position: relative;
	width: 40px;
	height: 40px;
	cursor: pointer;
	z-index: 100;
	background: $whiteColor;
	padding: rem(10);
	border-radius: 50%;

	span,
	&::before,
	&::after {
		content: "";
		transition: all 300ms ease 0ms;
		right: 0;
		left: 0;
		position: absolute;
		width: 60%;
		height: rem(2);
		margin: auto;

		background-color: $blackColor;
	}

	&::before {
		top: rem(10);
	}

	&::after {
		bottom: rem(10);
	}

	span {
		top: calc(50% - rem(1));
	}

	&.open {
		top: rem($mainOffset);
		right: rem($mainOffset);
		position: fixed;

		span {
			width: 0;
		}

		&::before {
			top: calc(50% - rem(1));
			transform: rotate(-45deg);
		}

		&::after {
			bottom: calc(50% - rem(1));
			transform: rotate(45deg);
		}
	}
}
</style>
