import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  RecaptchaVerifier,
} from "firebase/auth"

import { getDatabase, ref, set, get, child } from "firebase/database"

export default {
  actions: {
    async login({ dispatch, commit }, { email, password }) {
      try {
        await signInWithEmailAndPassword(getAuth(), email, password)

        //   const uid = await dispatch("getUid")
        //   const referense = ref(getDatabase())

        //   const getUserData = await get(child(referense, `users/${uid}`)).then(
        //     (data) => data.val(),
        //   )

        //   sessionStorage.setItem("curentUser", JSON.stringify(UserDataStorage))
      } catch (e) {
        console.log(e)

        commit("setError", e)
        throw e
      }
    },

    async register({ dispatch, commit }, { email, password, displayName }) {
      try {
        await createUserWithEmailAndPassword(
          getAuth(),
          email,
          password,
          displayName,
        )
        //   const auth = getAuth()

        //   await updateProfile(auth.currentUser, {
        //     displayName: displayName,
        //     photoURL: "https://example.com/jane-q-user/profile.jpg",
        //   })

        const uid = await dispatch("getUid")
        const db = await getDatabase()

        console.log(uid)

        set(ref(db, `users/${uid}/`), {
          info: {
            username: displayName,
            email: email,
            cart: [],
            orders: [],
            address: {},
            phone: {},
          },
          roles: { REGISTRED: "REGISTRED" },
        })

        // authfirebase getAuth()
      } catch (e) {
        console.log(e)
        commit("setError", e)

        throw e
      }
    },

    async chekUserRoles() {
      const uid = await dispatch("getUid")

      console.log(uid)
    },
    getUser() {
      const auth = getAuth()
      const user = auth.currentUser

      return user ? user : null
    },
    getUid() {
      const auth = getAuth()
      const user = auth.currentUser

      return user ? user.uid : null
    },
    getUserToken() {
      const auth = getAuth()
      const user = auth.currentUser

      return user ? user.accessToken : null
    },

    getUserName() {
      const auth = getAuth()
      const user = auth.currentUser
      console.log(user)
      return user ? user.name : null
    },
    getUserData() {
      const auth = getAuth()
      const user = auth.currentUser
      // console.log(user)
      return user ? user : null
    },
    async logout({ commit }) {
      const auth = getAuth()
      await signOut(auth)
      commit("clearInfo")
      console.log("signOut is worked...")
      //   .then(() => {
      //     this.$router.push("/login")
      //     console.log("signOut is ok ....")
      //   })
      //   .catch((error) => {
      //     console.log("eror signOut...!")
      //     // An error happened.
      //   })
    },
  },
}
