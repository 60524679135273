export default {
  LOGGED_IN: "LOGGED_IN",
  REGISTRED: "REGISTRED",

  CAN_CREATE_CARD: "CAN_CREATE_CARD",
  CAN_EDIT_CARD: "CAN_CREATE_CARD",
  CAN_DELETE_CARD: "CAN_CREATE_CARD",

  CAN_CREATE_ORDER: "CAN_CREATE_CARD",
  CAN_READ_ALL_ORDER: "CAN_READ_ALL_ORDER",
  CAN_EDIT_ORDER: "CAN_EDIT_ORDER",
  CAN_DELETE_ORDER: "CAN_DELETE_ORDER",

  CAN_READ_CART: "CAN_READ_ALL_CART",
  CAN_CREATE_CART: "CAN_CREATE_CART",
  CAN_READ_ALL_CART: "CAN_READ_ALL_CART",
  CAN_DELETE_CART: "CAN_DELETE_CART",
  CAN_EDIT_CART: "CAN_EDIT_CART",

  CAN_READ_ADMIN_PANEL: "CAN_READ_ADMIN_PANEL",

  CAN_READ_USER: "CAN_READ_USER",
  CAN_ADD_USER: "CAN_ADD_USER",
  CAN_EDIT_USER: "CAN_EDIT_USER",
  CAN_REMOVE_USER: "CAN_REMOVE_USER",
  CAN_BLOCK_USER: "CAN_BLOCK_USER",

  SUPERUSER: "SUPERUSER",
}

//cvpPiRrhVGDxQF8z
