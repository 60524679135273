<template>
	<header class="header" :class="{sticky: distanse> 500 }">
		<div class="header__container">
			<RouterLink to="/products" class="link-logo">
				<img src="../assets/images/logo__dark.svg" class="logo header__logo">
			</RouterLink>
			<Menu v-if="!isMobile" />
	
			<WorkTime v-if="!isMobile" />
	
			<div class="header--item">
				<a class="phone__block" href="tel:+79183235554" title="телефон">
					<img src="../assets/images/phone.svg" />
					<span>8 (918) 323-55-54</span></a>
				<DropDownMenu />
	
				<!-- v-bind:class="{ 'new-class': isClassAdded }" -->
				<MobileMenu v-if="isMobile" />
			</div>
		</div>
	</header>
</template>

<script>

import Menu from './MainMenu.vue'
import MobileMenu from './MobileMenu.vue'
import DropDownMenu from './DropDownMenu.vue';
import WorkTime from './WorkTime.vue'

export default {
  components: { Menu, DropDownMenu, MobileMenu, WorkTime },
  data() {
    return {
      distanse: 0,

    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);

  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {


    isMobile() {
      return this.$screen()
    }
  },
  methods: {
    handleScroll() {
      this.distanse = window.scrollY
    }
  },
  //   handleAddClassToSubchild() {
  //     this.isClassAdded = true;

  //     console.log(this.$emit)
  //   },
}



</script>








