<template>
	<div class="to__cart">
		<RouterLink to="/cart" class="cart__button" title="корзина" v-show="countProduct > 0">
			<Transition name="bounce">
				<div class="count" v-if="countProduct > 0 ">{{countProduct}}</div>
			</Transition>
			<img class="add__cart" src="@/assets/images/basket.svg" />
			<div class="total">{{cartTotal}} ₽</div>
		</RouterLink>
	
	</div>
</template>

<script>
export default {
  computed: {
    countProduct() {
      return this.$store.state.cart.cartProducts.length;
    },
    cartTotal() {
      return this.$store.state.cart.cartTotal;
    }
  }
}
</script>


<style lang="scss" scoped>
@use 'sass:math';
@import 'src/scss/config/functions';
@import 'src/scss/config/mixins';
@import '/src/scss/config/patternSelectors';
@import 'src/scss/config/vars.scss';

.bounce-enter-active {
	animation: bounce-in 0.5s;
}

.bounce-leave-active {
	animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(1);
	}
}


.cart__button {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: rem(14);
	position: fixed;
	bottom: rem(60);
	right: rem(10);
	gap: rem(10);
	padding: rem(10);
	width: rem(80);
	height: rem(60);
	bottom: rem(60);
	right: rem(10);
	box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.171);
	background: $redColor;
	z-index: 70;
	transition: 0.5s;

	&:hover {
		transform: scale(1.1);
	}

	.total {
		font-size: 14px;
		color: $whiteColor;
		font-weight: 300;
		//	width: rem(80);
		// position: absolute;
		// bottom: rem(-30);
		// padding: rem(8);
		// left: 0;
		// right: 0;
		// margin: auto;
	}

	.count {
		background: rgb(255, 255, 255);
		font-size: rem(12);
		font-weight: 900;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		position: absolute;
		left: rem(-5);
		top: rem(-5);
		border-radius: 50px;
	}
}
</style>