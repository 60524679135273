import { createApp } from "vue"
import App from "./App.vue"
import router from "./router/router"
import store from "./store"
import Vue3TouchEvents from "vue3-touch-events"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import "firebase/auth"
import "firebase/database"
import "./registerServiceWorker"
import "aos/dist/aos.css"
import messagePlugin from "./utils/message.plugin"
import distanse from "./utils/screen"
import statusPlugin from "./utils/status.plugin"

const firebaseConfig = {
  apiKey: "AIzaSyAL_rXSeZQeqB6CFPagR3JpxjaBc-kQuyg",
  authDomain: "divine-command-308515.firebaseapp.com",
  projectId: "divine-command-308515",
  storageBucket: "divine-command-308515.appspot.com",
  messagingSenderId: "807233153454",
  appId: "1:807233153454:web:add6fa80530cabcc6f003f",
  measurementId: "G-5ZXFY4QCQX",
  databaseURL:
    "https://divine-command-308515-default-rtdb.europe-west1.firebasedatabase.app/",
}

let app
const firebase = initializeApp(firebaseConfig)
const authfirebase = getAuth(firebase)

onAuthStateChanged(authfirebase, () => {
  if (!app) {
    app = createApp(App).provide("authfirebase", authfirebase)

    app
      .use(store)
      .use(router)
      .use(distanse)
      .use(Vue3TouchEvents)
      .use(messagePlugin)
      .use(statusPlugin)
      .mount("#app")
  }
})
