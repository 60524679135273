<template>
	<div class="drop__down" v-if="userName">
		<!-- v-bind:class="{ 'new-class': isClassAdded }" -->
		<!-- :class=" {open: dropOpen}" -->
	
		<button class="user__name" @click.prevent="showMenu" :class="{open: dropOpen}">
			<img src="../assets/images/icons/user.svg">
			<span>{{userName}}</span>
		</button>
		<nav v-show="dropOpen">
			<router-link v-for="item in menuData" v-bind:key="item.id" :to="item.route">{{item.title}}</router-link>
			<a @click="logout">выйти</a>
		</nav>
	
	</div>
	<button class="enter__button" @click="this.$router.push('/login')" v-else>
		<img src="../assets/images/icons/user.svg"><span>Войти</span>
	</button>
</template>


<script>
import menuLists from '@/lists/menuLists';
import messages from '@/lists/messageList'

export default {


  data: () => ({
    dropOpen: false,
    //  isClassAdded: false

  }),
  computed: {
    menuData() {


      return !this.$store.getters.info.administrator ? menuLists.userMenu : menuLists.adminMenu

    },
    userName() {
      if (!this.$store.getters.info) {
        return null
      } else {
        return this.$store.getters.info.username
      }
    },
  },
  //   watch: {
  //     userName() {
  //       if (!this.$store.getters.info) {
  //         return null
  //       } else {
  //         return this.$store.getters.info.username
  //       }
  //     },
  //   },

  mounted() {
    document.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    showMenu() {
      this.dropOpen = !this.dropOpen
    },
    onClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.dropOpen = false;
      }
    },



    onEnter() {

    },

    async logout() {
      await this.$store.dispatch('logout')
      this.$store.commit('clearCart')
      this.$successMessage(messages.loguot)
      setTimeout(() => { this.$router.push('/login') }, 1500)
    }
  },


}
</script>


<style lang="scss" scoped>
@use 'sass:math';
@import 'src/scss/config/functions';
@import 'src/scss/config/mixins';
@import '/src/scss/config/patternSelectors';
@import 'src/scss/config/vars.scss';

.drop__down {
	max-width: 200px;
	display: flex;
	flex-direction: column;
	position: relative;



	.user__name {
		background: transparent;
		display: flex;
		align-items: center;
		gap: rem(5);
		cursor: pointer;
		white-space: nowrap;

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid rgb(0, 0, 3);
			transition: 0.5s;
		}

		&.open {
			&:after {
				transform: rotate(180deg);
			}
		}


		.mobile & {
			border-radius: 50px;
			padding: 0;
			width: 40px;
			height: 40px;
			justify-content: center;
			align-items: center;

			span {
				display: none
			}

			img {
				width: rem(20);
			}
		}
	}


	nav {
		animation: 0.5s loadElem;
		z-index: 999;
		right: 0;
		background: $whiteColor;
		top: 0;
		gap: 0.5rem;
		position: absolute;
		transform: translateY(50px) scale(1);
		transition: 0.5s;
		visibility: visible;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: fit-content;

		a {
			width: 100%;
			text-align: center;
			padding: rem(8) rem(16);

			&:hover {
				cursor: pointer;
				background: $redColor;
				color: $whiteColor;
			}
		}
	}
}
</style>