// export default {

// }

export default {
  install(Vue, options) {
    Vue.config.globalProperties.$getStatus = function (status) {
      let textStatus

      switch (status) {
        case "created":
          textStatus = "создан"
          break
        case "work":
          textStatus = "в работе"
          break

        case "delevery":
          textStatus = "в доставке"
          break

        case "сanсel":
          textStatus = "отменен"
          break
        case "ready":
          textStatus = "готов"
          break

        case "pay":
          textStatus = "оплачен"
          break
        default:
          break
      }

      return textStatus
    }
  },
}
