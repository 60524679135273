import { createRouter, createWebHistory } from "vue-router"
import { getDatabase, ref, onValue } from "firebase/database"
import FooterLayout from "@/components/Footer.vue"
import HeaderLayout from "@/components/Header.vue"
import { getAuth } from "firebase/auth"
import HeaderAdmin from "@/components/HeaderAdmin.vue"
import EmptyLayout from "@/layouts/EmptyLayout.vue"
import AdminLayout from "@/layouts/AdminLayout.vue"
import MainLayout from "@/layouts/MainLayout.vue"

import roles from "@/lists/roleList"
import { AssesGuard } from "./middleware/assesGuard.middelware"

const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: MainLayout }, //layout: "main"
    component: () => import("@/views/Home.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { layout: MainLayout, auth: false },
    component: () => import("@/views/NotFound.vue"),
  }, // 404

  {
    path: "/login",
    name: "login",
    meta: { layout: EmptyLayout, auth: false },
    component: () => import("@/views/Login.vue"),
  },

  {
    path: "/registration",
    name: "registration",
    meta: { layout: EmptyLayout, auth: false },
    component: () => import("@/views/Registration.vue"),
  },

  {
    path: "/products",
    name: "products",
    component: () => import("@/views/Products.vue"),
    meta: { layout: MainLayout }, //layout: "main"
  },
  {
    path: "/product/:id",
    name: "product",
    component: () => import("@/views/Product.vue"),
    meta: { layout: MainLayout }, //layout: "main"
  },

  {
    path: "/delevery",
    name: "delevery",
    component: () => import("@/views/Delevery.vue"),
    meta: { layout: MainLayout }, //layout: "main"
  },

  {
    path: "/campaign",
    name: "campaign",
    component: () => import("@/views/Campaign.vue"),
    meta: { layout: MainLayout }, //layout: "main"  // roles: [roles.LOGGED_IN]
  },

  {
    path: "/contacts",
    name: "contacts",
    component: () => import("@/views/Contact.vue"),
    meta: { layout: MainLayout }, //layout: "main"
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("@/views/CartPage.vue"),
    meta: { layout: MainLayout, auth: false }, //layout: "main"
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/Orders.vue"),
    meta: { layout: MainLayout, auth: true }, //layout: "main"
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/Settings.vue"),
    meta: { layout: MainLayout, auth: true }, //layout: "main"
  },

  {
    path: "/denied",
    name: "denied",
    component: () => import("@/views/AssesDenided.vue"),
    meta: { layout: MainLayout }, //layout: "main"
  },

  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/Admin.vue"),
    meta: {
      layout: AdminLayout,
      auth: true,
      roles: [roles.CAN_CREATE_CARD, roles.CAN_EDIT_CARD, roles.SUPERUSER],
    },
    //auth: false, uid: true
  },
  {
    path: "/admin-order",
    name: "admin-order",
    component: () => import("@/views/OrderPageAdmin.vue"),
    meta: {
      layout: AdminLayout,
      auth: true,
      roles: [roles.CAN_CREATE_CARD, roles.CAN_EDIT_CARD, roles.SUPERUSER],
    },
  },

  {
    path: "/orders/:uid/:orderId",
    name: "orderDetails",
    props: true,
    component: () => import("@/views/OrderDetails.vue"),

    meta: {
      layout: AdminLayout,
      auth: true,
      roles: [roles.CAN_CREATE_CARD, roles.CAN_EDIT_CARD, roles.SUPERUSER],
    },
  },

  //   {
  //     path: "/admin-order/orders/:uid/:orderId",
  //     name: "order",
  //     component: () => import("@/views/OrderDetails.vue"),

  //     meta: {
  //       layout: AdminLayout,
  //       auth: true,
  //       roles: [roles.CAN_CREATE_CARD, roles.CAN_EDIT_CARD, roles.SUPERUSER],
  //     },
  //   },
]

const router = createRouter({
  linkActiveClass: "active",
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.auth)
  const currentUser = getAuth().currentUser

  const asses = await AssesGuard(to, next)

  //   console.log(asses)
  //   const adminToken =

  //     "eyJhbGciOiJSUzI1NiIsImtpZCI6ImYwOGU2ZTNmNzg4ZDYwMTk0MDA1ZGJiYzE5NDc0YmY5Mjg5ZDM5ZWEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZGl2aW5lLWNvbW1hbmQtMzA4NTE1IiwiYXVkIjoiZGl2aW5lLWNvbW1hbmQtMzA4NTE1IiwiYXV0aF90aW1lIjoxNzE5MTAyMzg5LCJ1c2VyX2lkIjoibnJ5aHZ4TzBCQ01xOTdwMElpckhwYkJicm4wMyIsInN1YiI6Im5yeWh2eE8wQkNNcTk3cDBJaXJIcGJCYnJuMDMiLCJpYXQiOjE3MTkxMDIzODksImV4cCI6MTcxOTEwNTk4OSwiZW1haWwiOiJrLnYudWdheUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiay52LnVnYXlAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.OcgCBMlmlYB2i0MQ_6_pskRU0tLgnlH3Z6tEr1HetNGsJ3-XYBYIrCRsh6ihPQlmlmr_glZwaiT-nVnizRVejNzrh9fJ03wipcFJpyar3f21WIVcIlFgX9cLisVMaFk-paDSJ9FGHgtlh9DvmqZCVIXkkjAeJ1CccQJe28oShPM32PN0elUNN0YDq0IuwvuzUcV1UmBR2-14SVSKNGllfWkOBHReNLyq4LMOhW9YN2UnvvvMc4lE7XHX1tMtpV9Tn3q_CGAYtz0BdSniaDajhvjMnBaXKwThkZEUTRwFwcFKJ2z8wEPswNdJ5Mkt-DaZDstRc7lDtBc13aCmMs88Yg"
  //   const getToken = JSON.parse(sessionStorage.getItem("token"))

  //   console.log(getToken.user.stsTokenManager.accessToken)
  if (requireAuth && !currentUser) {
    next("/login")
  }
  if (asses === false) {
    next("/denied")
  } else {
    next()
  }
})

// router.beforeEach((to, from,next) => {
//   AssesGuard(to, from, next)
// })
export default router
