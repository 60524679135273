<template>
	<div class="admin__layout">
		<HeaderAdminVue />
		<div class="page__container">
			<router-view />
		</div>
	</div>
</template>



<script>
import HeaderAdminVue from '../components/HeaderAdmin.vue'

export default {
  components: { HeaderAdminVue },
  computed: {

    isMobile() {
      return this.$screen()
    },


  },



  async mounted() {
    if (this.$store.getters.info) {
      //  if (!Object.keys( this.$store.getters.info).length > 0) {
      await this.$store.dispatch('fetchInfo')
    }
  },

}
</script>