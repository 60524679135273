import { getDatabase, ref, set, get, child, onValue } from "firebase/database"

export default {
  state: {
    info: {},
  },
  mutations: {
    setInfo(state, info) {
      state.info = info
    },
    clearInfo(state) {
      state.info = {}
    },
  },
  actions: {
    async fetchInfo({ dispatch, commit }) {
      try {
        const uid = await dispatch("getUid")
        const db = getDatabase()
        const dbref = ref(db, `users/${uid}/info`)
        await onValue(dbref, (snapshot) => {
          commit("setInfo", snapshot.val())
        })
      } catch (e) {
        throw e
      }
    },
  },
  getters: {
    info: (s) => s.info,
  },
}
