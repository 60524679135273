
<script>
import AOS from "aos";
import FooterMenu from '@/components/FooterMenu.vue';

export default {
  components: { FooterMenu },

  mounted() {
    AOS.init();
  },

}
</script>

<template>
	<footer class="footer">
		<div class="footer__container">
			<div class="footer--item menu" data-aos="fade-up" data-aos-duration="800">
				<RouterLink to="/products" class="link-logo">
					<img src="../assets/images/logo__light.svg" class="logo">
				</RouterLink>
				<FooterMenu />
			</div>
	
	
			<div class="footer--item contacts" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
				<div class="contacts--wrap">
					<div class="contacts--elem">
						<img src="../assets/images/icons/contact.svg">
						<span>Яблоновский, ул.Космическая 41/1</span>
					</div>
					<div class="contacts--elem">
						<img src="../assets/images/icons/phone_footer.svg">
						<a href="tel:+79183235554" title="телефон">8918 323 555 4</a>
					</div>
				</div>
			</div>
	
			<div class="footer--item delevery" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
				<span>скидка -20% на сеты на вынос и в зале</span>
				<span>Доставка Яблоновский, Энем, Новая Адыгея, Козет, п.Новый</span>
			</div>
		</div>
		<div class="copyrights"><span>© "Суши Азия" все права защищены</span>
			<RouterLink to="/politics">
				Политика конфиденциальности
			</RouterLink>
			<RouterLink to="/user_politics">
				Пользовательское соглашение
			</RouterLink>
		</div>
	</footer>
</template>

<style lang="scss">
@use 'sass:math';
@import 'src/scss/config/functions';
@import 'src/scss/config/mixins';
@import '/src/scss/config/patternSelectors';
@import 'src/scss/config/vars.scss';

.footer {
	//background: $blackColor;
	background: $redColor;
	padding: rem(50) 0 0;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: rem(20);
	overflow: hidden;

	&__container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: rem(30);
		justify-content: space-between;
		width: 100%;

		@include media(1024) {
			flex-direction: column;
			align-items: flex-start;
		}


	}

	.footer--item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: stretch;
		gap: rem(20);
		height: rem(180);


		.logo {
			width: 150px;

		}


		&.menu {
			flex-basis: 33%;
			align-items: flex-start;
			justify-content: flex-start;
			gap: rem(30);
		}

		&.contacts {
			align-items: center;
			justify-content: center;
			border-right: 1px solid $bgColor;
			//border-left: 1px solid #FFEBEB15;
			border-left: 1px solid $bgColor;
			flex-basis: 33%;
			gap: rem(10);

			.contacts--elem {
				display: flex;
				//color: $bgColor;
				color: $blackColor;
				gap: rem(10);

				a,
				span {
					font-weight: 300;
				}

				img {
					width: rem(16);
				}
			}

			@include media(1024) {
				border: none;
			}


			//	@include adaptiv-font(25, 18);

			.contacts--wrap {
				flex-direction: column;
				display: inherit;
				gap: rem(20);
			}
		}

		&.delevery {
			justify-content: center;
			flex-basis: 33%;
			//	@include adaptiv-font(25, 18);
			font-weight: 300;

		}
	}

	span,
	a {
		//	color: $bgColor;
		color: $bgColor;
		font-weight: 300;

	}


}


.contacts {
	display: flex;
	gap: rem(50);
}


.copyrights {
	//border-top: 1px solid #FFEBEB15;
	display: flex;
	justify-content: center;
	padding: rem(20);
	flex-wrap: wrap;
	gap: rem(20);
	color: $whiteColor;
	font-size: rem(12);
	font-weight: 300;
}
</style>