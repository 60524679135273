<template>
	<nav class="header__menu main-menu">
		<!-- :class="{open: isOpen}" -->
		<routerLink v-for="link in links" :key="link.url" :to="link.url" @click="isOpen =false" class="menu__item">
			{{ link.title }}
		</routerLink>
	</nav>
</template>

<script>

import menuLists from '@/lists/menuLists';
export default {


  data: () => ({
    //  isOpen: false,
    links: menuLists.mainMenu
  }),
  mounted() {
    this.ref = this.$refs.element;
  }

}
</script>

<style lang="scss">
@use "sass:math";
@import "src/scss/config/functions";
@import "src/scss/config/mixins";
@import "/src/scss/config/patternSelectors";
@import "src/scss/config/vars.scss";

.main-menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-basis: 30%;



	a {

		position: relative;
		padding: rem(8) rem(16);
		white-space: nowrap;
		transition: 0.5s;
		font-weight: 300;

		&:hover {
			color: $redColor;
		}

		&.active {
			border-radius: 100px;
			background-color: $redColor;
			color: $bgColor;
		}
	}
}
</style>
