


<script>

import AppLayout from '@/layouts/AppLayout.vue';


export default {
  components: { AppLayout },





  // <main :id="layout" class="page">
  // 		<div class="page__container">

  // 			<router-view v-slot="{ Component }">
  // 				<Transition name="slide-fade" :duration="600"> <!-- slide-fade -->
  // 					<component :is="Component" />
  // 				</Transition>
  // 			</router-view>
  // 		</div>
  // 	</main>

}

// if (auth().currentUser) { router.push('/') } else { router.push('/login') }
</script>



<template>
	<div data-observ></div>
	
	<AppLayout>
		<router-view />
	</AppLayout>
	
	<div class="load__left">С</div>
	<div class="load__right">А</div>
</template>


<style lang="scss">
@import './scss/style.scss'
</style>


