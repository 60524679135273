

<script>
import messages from '@/lists/messageList'
import DropDownMenu from './DropDownMenu.vue';

export default {
  components: { DropDownMenu },

  methods: {
    async logout() {
      await this.$store.dispatch('logout')
      this.$successMessage(messages.loguot)
      setTimeout(() => { this.$router.push('/login') }, 1500)
    }
  },


};
</script>


<template>
	<header class="header admin__header">
		<div class="header__container">
			<button class="button__back" @click="this.$router.go(-1)"><span>назад</span></button>
	
	
			<DropDownMenu />
		</div>
	</header>
</template>

