// /* Добавление класса touch для HTML если браузер мобильный */
// function screen() {
//   // Добавление класса _touch для HTML если браузер мобильный
//   if (isMobile.any()) {
//     html.classList.add("touch")
//   }
// }

export default {
  install(Vue, options) {
    const isMobile = {
      Android: () => navigator.userAgent.match(/Android/i),
      BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
      iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
      Opera: () => navigator.userAgent.match(/Opera Mini/i),
      Windows: () => navigator.userAgent.match(/IEMobile/i),
      any: () =>
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows(),
    }
    Vue.config.globalProperties.$screen = function () {
      return isMobile.any() ? true : false
    }
  },
}
