import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"

export default {
  install(Vue, options) {
    Vue.config.globalProperties.$message = function (text) {
      toast(text, {
        autoClose: 1000,
        rtl: true,
        limit: 3,
        type: "info",
      })
    }

    Vue.config.globalProperties.$errorMessage = function (text) {
      toast.error(text, {
        autoClose: 1000,
        rtl: true,
        limit: 3,
        position: toast.POSITION.TOP_CENTER,
      })
    }

    Vue.config.globalProperties.$successMessage = function (text) {
      toast(text, {
        autoClose: 1000,
        rtl: true,
        limit: 3,
        type: "success",
        position: toast.POSITION.TOP_CENTER,
      })
    }

    Vue.config.globalProperties.$addCartMessage = function (text) {
      toast(text, {
        autoClose: 500,
        rtl: true,
        limit: 5,
        type: "default",
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    Vue.config.globalProperties.$order = function (text) {
      toast(text, {
        autoClose: 10000,
        rtl: true,
        limit: 5,
        type: "success",
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    Vue.config.globalProperties.$warningMessage = function (text) {
      toast(text, {
        autoClose: 1000,
        rtl: true,
        limit: 3,
        type: "warning",
        position: toast.POSITION.TOP_CENTER,
      })
    }
    Vue.config.globalProperties.$defualtMessage = function (text) {
      toast(text, {
        autoClose: 1000,
        rtl: true,
        limit: 3,
        type: "default",
        position: toast.POSITION.TOP_CENTER,
      })
    }
  },
}
