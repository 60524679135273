export default {
  userMenu: [
    { id: 1, title: "корзина", route: "/cart" },
    { id: 2, title: "заказы", route: "/orders" },
    { id: 3, title: "настройки", route: "/settings" },
  ],
  adminMenu: [
    { id: 1, title: "управление", route: "/admin" },
    { id: 1, title: "заказы", route: "/admin-order" },
    { id: 1, title: "пользователи", route: "/users" },
  ],
  mainMenu: [
    {
      title: "Меню",
      url: "/products",
    },
    {
      title: "Доставка и оплата",
      url: "/delevery",
    },
    {
      title: "Акции",
      url: "/campaign",
    },
    {
      title: "Контакты",
      url: "/contacts",
    },
  ],

  categories: [
    { id: "0", value: "all", text: "все", active: true },
    { id: "1", value: "sushi", text: "суши", active: false },
    { id: "2", value: "sets", text: "сеты", active: false },
    { id: "3", value: "rolls", text: "роллы", active: false },
    { id: "4", value: "soups", text: "супы", active: false },
    { id: "5", value: "hots", text: "горячие блюда", active: false },
    { id: "11", value: "salads", text: "салаты", active: false },
    { id: "7", value: "beverages", text: "закуски", active: false },
    { id: "9", value: "pizza", text: "пицца", active: false },
    { id: "10", value: "news", text: "новинки", active: false },
  ],
}
