<template>
	<div class="time__block">
		<div class="text time-item">
			<span class="time--elem day">вс-пн</span>
			<span class="time--elem time">11:00-22:00</span>
		</div>
	</div>
</template>


<style lang="scss">
@use "sass:math";
@import "src/scss/config/functions";
@import "src/scss/config/mixins";
@import "/src/scss/config/patternSelectors";
@import "src/scss/config/vars.scss";


.time__block {
	display: flex;
	align-items: center;
	flex-basis: 10%;
	height: rem(40);

	.time-item {
		display: flex;
		flex-direction: column;
		margin-left: 10px;

		@include media(480) {
			margin-left: 0;
		}
	}

	.mobile & {
		position: absolute;
		top: rem($mainOffset);
		left: rem($mainOffset);


		span {
			color: $whiteColor;
		}
	}




	span {
		@include adaptiv-font(14, 12);


	}


}
</style>