export default {
  loguot: "Вы успешно вышли из системы",
  login: "Вы успешно вошли в систему",
  regisred: "Вы успешно зарегистрированы",
  authError: "Ошибка авторизации",
  usedEmail: "Такой пользователь уже существует",
  baseError: "что-то пошло не так((",
  userInvalid: "пользователь пароль не найдены",
  succssessOrder: "Заказ успешно создан",
}
