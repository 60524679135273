import { getDatabase, ref, child, get } from "firebase/database"

export default {
  actions: {
    async fetchProduct({ dispatch, commit }) {
      try {
        let data = await get(child(ref(getDatabase()), "catalog"))
        return data.val()
      } catch (e) {
        commit("setError", e)
        throw e
      }
    },
  },
}
