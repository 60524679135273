import {
  getDatabase,
  ref,
  push,
  set,
  get,
  child,
  onValue,
  query,
  orderByChild,
  equalTo,
} from "firebase/database"

export default {
  state: {
    orders: [],
    curentOrder: null,
  },
  mutations: {
    pushOrder(state, order) {
      state.orders.push(order)
    },
    setOrders(state, orders) {
      state.orders = orders
    },
    clearOrder(state) {
      state.orders = []
    },

    setCurrentOrder(state, order) {
      state.curentOrder = order
    },
  },
  actions: {
    async addOrder({ dispatch, commit }, { cart, total, name, phone }) {
      try {
        const uid = await dispatch("getUid")
        const db = getDatabase()
        //   var formattedDate = new Date().toLocaleString("ru", {
        //     day: "2-digit",
        //     month: "2-digit",
        //     year: "numeric",
        //     hour: "2-digit",
        //     minute: "2-digit",
        //   })
        const newOrderRef = push(ref(db, `orders/${uid}`))
        let id = "SA-" + Date.parse(new Date())
        const orderData = {
          id: id,
          user: name,
          uid: uid,
          phone: phone,
          cart: cart,
          date: new Date(),
          status: "created",
          total: total,
        }

        // Сохраняем заказ в базе данных
        await set(newOrderRef, orderData)

        // Коммитим данные заказа в состояние
        commit("setOrder", orderData)
      } catch (error) {
        console.error("Error adding order: ", error)
      }
    },

    //  async getAllOrders({ commit, dispatch }) {
    //    try {
    //      const db = getDatabase()
    //      const ordersRef = ref(db, "orders")

    //      const snapshot = await get(ordersRef)
    //      if (snapshot.exists()) {
    //        const ordersData = snapshot.val()
    //        const ordersArray = Object.keys(ordersData).flatMap((uid) =>
    //          Object.keys(ordersData[uid]).map((orderId) => ({
    //            id: orderId,
    //            uid: uid,
    //            ...ordersData[uid][orderId],
    //          })),
    //        )

    //        commit("setOrders", ordersArray)
    //      } else {
    //        commit("setOrders", [])
    //      }
    //    } catch (error) {
    //      console.error("Error fetching orders: ", error)
    //    }
    //  },

    async getAllOrders({ commit, dispatch }) {
      try {
        const db = getDatabase()
        const ordersRef = ref(db, "orders")

        const snapshot = await get(ordersRef)
        if (snapshot.exists()) {
          const ordersData = snapshot.val()
          const ordersArray = Object.keys(ordersData).flatMap((uid) =>
            Object.keys(ordersData[uid]).map((orderId, id) => ({
              orderId: orderId,
              id: id,
              uid: uid,
              ...ordersData[uid][orderId],
            })),
          )

          commit("setOrders", ordersArray)
        } else {
          commit("setOrders", [])
        }
      } catch (error) {
        console.error("Error fetching orders: ", error)
      }
    },

    //  async fetchOrders({ commit, dispatch }, id) {
    //    try {
    //      const db = getDatabase()
    //      const ordersRef = ref(db, "orders")

    //      const snapshot = await get(ordersRef)
    //      if (snapshot.exists()) {
    //        const ordersData = snapshot.val()
    //        const ordersArray = Object.keys(ordersData).flatMap((uid) =>
    //          Object.keys(ordersData[uid]).map((orderId, hashID) => ({
    //            id: orderId,
    //            hashID: c,
    //            uid: uid,
    //            ...ordersData[uid][orderId],
    //          })),
    //        )
    //        console.log("fetchOrders ARRAY", ordersArray)

    //        return ordersArray.find((p) => p.id === id)
    //        //  commit("setOrders", ordersArray)
    //      } else {
    //        return []
    //        //  commit("setOrders", [])
    //      }
    //    } catch (error) {
    //      console.error("Error fetching orders: ", error)
    //    }
    //  },

    async getOrderByUid({ commit, dispatch }, { uid, orderId }) {
      const dbRef = ref(getDatabase())

      console.log(`uid in orders: ${uid}, 
			ordersId in orders: ${orderId}`)

      try {
        const snapshot = await get(child(dbRef, `orders/${uid}/${orderId}`)) // Путь изменен на `orders/${uid}/${orderId}`
        if (snapshot.exists()) {
          const orderData = snapshot.val()
          console.log("Order data:", orderData)
          commit("setCurrentOrder", orderData)

          //  return orderData
        } else {
          console.log("No data available")
          commit("setCurrentOrder", null)
        }
      } catch (error) {
        console.error("Error getting order data:", error)
        return null
      }
    },

    async getOrderByFId({ commit, dispatch }, id) {
      try {
        const db = getDatabase()
        const ordersRef = ref(db, "orders")

        const snapshot = await get(ordersRef)
        if (snapshot.exists()) {
          const ordersData = snapshot.val()
          const ordersArray = Object.keys(ordersData).flatMap((uid) =>
            Object.keys(ordersData[uid]).map((orderId, id) => ({
              orderId: orderId,
              id: id,
              uid: uid,
              ...ordersData[uid][orderId],
            })),
          )

          return ordersArray.find((p) => p.id === id)
        } else {
          return []
        }
      } catch (error) {
        console.error("Error fetching orders: ", error)
      }
    },

    async findOrdersByUid({ dispatch, commit }, uid) {
      try {
        const db = getDatabase()
        const ordersRef = ref(db, "orders")

        // Запрос для поиска заказов по uid
        const ordersQuery = query(ordersRef, orderByChild("uid"), equalTo(uid))
        const snapshot = await get(ordersQuery)

        if (snapshot.exists()) {
          const ordersData = snapshot.val()
          const ordersArray = Object.keys(ordersData).flatMap((orderId) => ({
            orderId: orderId,
            ...ordersData[orderId],
          }))

          console.log("Найденные заказы: ", ordersArray)
          return ordersArray
        } else {
          console.log("Заказы не найдены.")
          return []
        }
      } catch (error) {
        console.error("Ошибка при поиске заказов: ", error)
      }
    },
  },

  getters: {
    getOrderById: (state) => (uid) => {
      const item = state.orders.find((p) => p.uid === uid)

      return item ? item : null
    },
    orders: (o) => o.orders,

    curentOrder: (o) => o.curentOrder,
  },
}
