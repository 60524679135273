import { getAuth } from "firebase/auth"
import { getDatabase, ref, onValue, get, child } from "firebase/database"

async function AssesGuard(to, next) {
  const auth = await getAuth()

  if (!auth.currentUser) {
    return
  }
  const uid = auth.currentUser.uid
  const dbRef = ref(getDatabase())

  const userRoles = await get(child(dbRef, `users/${uid}/roles/`)).then(
    (data) => data.val(),
  )

  let metaRoles = to.meta.roles

  if (!metaRoles) return

  const chekAsses = getRoles(userRoles).some((el) => metaRoles.includes(el))

  function getRoles(roles) {
    let userRole = []
    for (let key in roles) {
      userRole.push(key)
    }
    return userRole
  }

  return chekAsses
}

export { AssesGuard }
