<template>
	<nav class="footer-menu">
		<routerLink v-for=" link in links" :key="link.url" :to="link.url" @click="isOpen =false" class="menu__item">
			{{ link.title }}
		</routerLink>
	</nav>
</template>

<script>


export default {
  data: () => ({
    isOpen: false,
    links: [
      {
        title: "Меню",
        url: "/catalgos",
      },
      {
        title: "Доставка и оплата",
        url: "/delevery",
      },
      {
        title: "Акции",
        url: "/campaign",
      },
      {
        title: "Контакты",
        url: "/contacts",
      },
    ],
  }),
  mounted() {
    this.ref = this.$refs.element;
  }

}
</script>

<style lang="scss">
@use "sass:math";
@import "src/scss/config/functions";
@import "src/scss/config/mixins";
@import "/src/scss/config/patternSelectors";
@import "src/scss/config/vars.scss";



.footer-menu {
	display: flex;
	justify-content: space-between;
	align-items: start;
	flex-wrap: wrap;
	flex-direction: column;
	gap: rem(10);

	a {
		position: relative;
		white-space: nowrap;
		font-weight: 300;
		transition: 0.5s;
	}
}
</style>
